<template>
  <div>
    <h1>Liste des taches terminées</h1>
  </div>
</template>

<script>
  export default {};
</script>

<style></style>
